import { ProjectsContainer, ProjectsContentContainer, ProjectListContainer } from './style/style';
import SwipeComponent from './ProjectSwiper'
import ProjectSwiper from './ProjectSwiper';


function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}

function ProjectsComponent() {



    const orderbutlerImages = importAll(require.context('../../images/orderbutlerImages', false, /\.(png|jpe?g|svg)$/));
    const planitImages = importAll(require.context('../../images/PlanitImages', false, /\.(png|jpe?g|svg)$/));

    const orderbutlerDescription = 'Eine Full-Stack Webapplikation, über welche der gesamte Grading-Prozess von Sammelkarten stattfindet. Kunden können Bestellungen aufgeben und diese' +
        ' dann über ihren optionalen Account einsehen und tracken.\nAußerdem können Admins der Grading-Firma Bestellungen bearbeiten, gegradete Karten erstellen und  ' +
        'Benutzerkonten, sowie Bestellungen verwalten.'
    const planitDescription = 'Planit ist eine Full-Stack Webapplikation, über die Events mit mehreren Personen geplant werden können. ' +
        'Benutzer erstellen Events und laden Freunde zu diesen ein. Anschließend werden freie oder belegte Zeitslots in einen Kalender eingetragen, woraufhin ' +
        'alle möglichen Termine berechnet werden. Durch ein Voting-System wird dann der best mögliche Termin festgelegt.'

    return (
        <ProjectsContainer>
            <h1>Projekt Übersicht</h1>

            <ProjectsContentContainer>
                <ProjectListContainer>
                    <ProjectSwiper
                        title='Order-Butler'
                        images={orderbutlerImages}
                        description={orderbutlerDescription}
                    />
                    <ProjectSwiper
                        title='Planit'
                        images={planitImages}
                        description={planitDescription}
                    />
                </ProjectListContainer>
            </ProjectsContentContainer>
        </ProjectsContainer>
    )
}

export default ProjectsComponent;