import styled from "styled-components";




export const ProjectsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 3%;
    width: 100%;
    align-items: center;
    height: 100vh;

    h1 {
        font-size: 2.5rem;
        color: #292929;
        padding: 0 20px 0 20px;
        border-bottom: 1px solid #292929;
        text-align: center;
        margin-bottom: 0px;
    }

    @media (max-width: 480px) {
        h1 {
            font-size: 1.5rem;
            padding: 0 5px 0 5px;
        }   
    }
`;

export const ProjectsContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 5% 0 0 0;
`;

export const ProjectListContainer = styled.div`
    display: flex;
    justify-content: center;
    gap: 50px;
    width: 90%;
    margin: 0 auto 0 auto;

    @media (max-width: 480px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    @media (max-height: 693px) {
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
`;

export const SingleProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;