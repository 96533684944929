import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import {
    ContentContainer, ProjectButtonContainer, ProjectButton, ScrollIconContainer, MobileHeadingContainer,
    MobileHeadingText, MobileSubheadingText, Header, HeaderBackground, ProjectsContainer, SingleProjectContainer
} from "./style/style";
import './style/style.scss';
import LandingSVG from '../../components/landingSVG';
import Divider from '@mui/material/Divider';
import { FullPage, Slide } from 'react-full-page';
import { animateScroll } from "./animateScroll";

import ProjectsComponent from "../../components/Projects";

function LandingPage() {

    var scrollMode = 'full-page';

    var mobileSpecificContent = null;
    var scrollIndicatorContent = null;

    const desktopSVG = <LandingSVG />
    const mobileContent = <MobileHeadingContainer>
        <MobileHeadingText>LUKAS LISTL</MobileHeadingText>
        <MobileSubheadingText>STUDENT / FREELANCE WEB DEVELOPER</MobileSubheadingText>
        <Divider flexItem />
    </MobileHeadingContainer>

    let screenHeight = window.innerHeight;
    const [headerContent, setHeaderContent] = useState('Wilkommen zu meinem Portfolio');

    let onScroll = (parameter) => {
        if (parameter.to == 1) {
            setHeaderContent('Meine Projekte');
        } else {
            setHeaderContent('Wilkommen zu meinem Portfolio');
        }
    }


    if (window.screen.width < 480 || window.screen.height < 693) {
        mobileSpecificContent = mobileContent;
        scrollMode = 'normal';
        scrollIndicatorContent = <ScrollIconContainer>
            <div className="scroll-down"> </div>
        </ScrollIconContainer>
    } else {
        mobileSpecificContent = desktopSVG;
        scrollIndicatorContent = <ScrollIconContainer>
            <div className="scroll-down"> </div>
            <div className="scroll-down"> </div>
        </ScrollIconContainer>
    }



    return (
        <div>
            <HeaderBackground />

            <FullPage
                beforeChange={onScroll}
                duration={500}
                scrollMode = {scrollMode}
            >
                <Header>
                    {headerContent}
                </Header>
                <Slide>
                    <ContentContainer>
                        {mobileSpecificContent}
                        <ProjectButtonContainer>
                            <ProjectButton
                                onClick={() => {
                                    animateScroll({ targetPosition: screenHeight, initialPosition: 0, duration: 500 })
                                    onscroll({ 'from': 0, 'to': 1 })
                                }}
                            > MEINE PROJEKTE </ProjectButton>
                        </ProjectButtonContainer>

                        {scrollIndicatorContent}

                    </ContentContainer>
                </Slide>
                <Slide>
                    <ContentContainer>
                        <ProjectsComponent />
                    </ContentContainer>
                </Slide>
            </FullPage>




        </div>
    )
}

export default LandingPage;