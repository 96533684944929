import React, { useEffect } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './style/style.scss'
import { SwiperTitle, SwiperDescription } from './style/style'

const ProjectSwiper = (props) => {


    let images = []
    for (var key in props.images) {
        if (props.images.hasOwnProperty(key)) {
            images.push(props.images[key])
        }

    }

    useEffect(() => {


        const swiper = new Swiper('.swiper', {
            modules: [Navigation, Pagination],
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }, []);

    // let imgWidth = (window.innerWidth > 480) ? 640 : 346;
    // let imgHeight = (window.innerWidth > 480) ? 312 : 168;
    let imgWidth = (window.innerWidth > 480 && window.innerHeight > 693) ? window.innerWidth / 3 : window.innerWidth / 1.2;
    let imgHeight = 0.49 * imgWidth;

//, width: {imgWidth}, height:{imgHeight}
    const swiperImages = []
    for (var i = 0; i < images.length; i++) {
        swiperImages.push(<div className="swiper-slide" key={i}><img src={images[i]} style={{maxWidth:'100%', maxHeight:'100%' }}  /></div>)
    }

    return (
        <div>
            <SwiperTitle>
                {props.title}
            </SwiperTitle>
            <div className="swiper" style={{ width: imgWidth, height: imgHeight }}>
                <div className="swiper-wrapper">
                    {swiperImages}
                </div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-button-next"></div>
            </div>
            <SwiperDescription>
                {props.description}
            </SwiperDescription>
        </div>
    )

}


export default ProjectSwiper;

