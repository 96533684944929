import React from 'react';

import './index.scss';
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LandingPage from './pages/LandingPage';
import NotFoundPage from './pages/404';

function App() {
  return (

    <div>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LandingPage />} />
          <Route path='/404' element={<NotFoundPage />} />
          <Route path='*' element={<Navigate to='/404' />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
