import styled from "styled-components";


export const SwiperTitle = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    font-weight: 400;
    margin-bottom: 20px;
`;

export const SwiperDescription = styled.div`
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    font-weight: 200;
    text-align: center;
    margin: 30px 10px 0 20px;

    @media (max-width: 480px) {
        font-size: 1.1em;
        margin-bottom: 30px;
    }  
    @media (max-height: 693px) {
        font-size: 1.1em;
        margin-bottom: 30px;
    }  
`;