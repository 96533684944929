import styled from "styled-components";

export const ContentContainer = styled.div`
    text-align: center;
    margin: 20vh 0 0 0;
    font-size: 1.5em;
    color: #292929;
    
    h1, h2 {
        font-weight: 300;
    }

    a {
        text-decoration: none;
        font-weight: 600;
        color: #292929;
        transition: 0.2s;

        &:hover {
           text-shadow: 0px 6px 5px rgba(41,41,41,0.7);
        }
        &:active {
                text-shadow: none;
            }
        }
`;