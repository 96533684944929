import styled from "styled-components";


export const Header = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 50px;
    padding: 10px 0 20px 0;
    background: transparent;
    background: linear-gradient(180deg, rgba(0,0,0,0.15), rgba(0,0,0,0.1), rgba(0,0,0,0));
    font-size: 1.4rem;
    z-index: 999;

    @media (max-width: 480px) {
        display: none;
    }
    @media (max-height: 693px) {
        display: none;
    }
`;

export const HeaderBackground = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    -webkit-mask: -webkit-linear-gradient(rgba(255, 255, 255, 1),rgba(255, 255, 255, 1),  rgba(255, 255, 255, 0));
    backdrop-filter: blur(2px);
    z-index: 900;

    @media(max-width: 480px) and (max-height: 693px) {
        display: none;
    }
    @media (max-height: 693px) {
        display: none;
    }
`;

export const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    height: 100vh;
`;

export const ProjectButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: auto 0 auto 0;
    

    @media (max-width: 480px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 40%;
        width: 100%;
        margin: 0;
    }   
    @media (max-height: 693px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 40%;
        width: 100%;
        margin: 0;
    }
`;

export const ProjectButton = styled.button`
    background: transparent;
    border: 2px solid #292929;
    border-radius: 5px;
    font-size: 2rem;
    padding: 10px 15px 10px 15px;
    transition: 0.2s;

    &:hover {
        background: #373129;
        border: 2px solid #373129;
        color: #E0D5C7;
        cursor: pointer;
        transition: 0.2s;
    }

    @media (max-width: 480px) {
        border: 1px solid #292929;
        font-size: 1.5rem;
        padding: 5px 10px 5px 10px;
    }
`;

export const ScrollIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    margin: auto 0 40px 0;

    @media (max-width: 480px) {
        margin: 0;
    }
`;

export const MobileHeadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
    margin: 0 auto 0 auto;
`;

export const MobileHeadingText = styled.p`
    font-size: 3rem;
    font-weight: 500;
    margin: 45px 0 20px 0;
    text-align: center;
`;

export const MobileSubheadingText = styled.p`
    font-size: 1.7rem;
    font-weight: 300;
    margin: 20px 0 30px 0;
    text-align: center;
`;




export const SingleProjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 30%;
`;