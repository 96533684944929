import React from "react";
import { ContentContainer } from "./style/style";

function NotFoundPage() {


    return (

        <ContentContainer>
            <h1>
                ヽ༼ ಠ益ಠ ༽ﾉ
                <br />
                <br />
                Ups ... da ist wohl etwas schief gelaufen.
            </h1>
            <h2>
                Diese Seite gibt es wohl nicht.
            </h2>
            <br />
            <h2>
                <a href="/">Hier</a> geht es zurück zur Startseite.
            </h2>
        </ContentContainer>
    )
}


export default NotFoundPage;